import React from "react";
import InputField from "../../../../FinalForm/InputField";
import CheckboxField from "../../../../FinalForm/CheckboxField";

const CommisionOnline = () => {

    return(
        <>
            <span className={"w-full text-base font-bold"}>Online prodej</span>
            <div className={"w-full flex flex-wrap bg-grey-200 mt-1 p-2 rounded"}>
                <div className={"flex w-1/4"}>
                    <InputField label={"Vstupenky %"} name={"online.ticketPercent"} inputType={"number"}
                                isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <div className={"flex flex-col w-full"}>
                        <div className={"flex w-full"}>
                            <InputField label={"Vstupenky - Kč/vstupenka"} name={"online.ticketPerKs"}
                                        inputType={"number"} isMandatory={true} min={0} step={'0.01'}/>
                        </div>
                        <div className={"flex w-full -mt-3 ml-1"}>
                            <CheckboxField name={"online.ticketPerKsWithZero"}
                                           label={"Počítat i z nulových"}/>
                        </div>
                    </div>
                </div>
                <div className={"flex w-1/4"}>
                    <InputField label={"Permanentky %"} name={"online.seasonTicketPercent"}
                                inputType={"number"} isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <div className={"flex flex-col w-full"}>
                        <div className={"flex w-full"}>
                            <InputField label={"Permanentky - Kč/permanentka"}
                                        name={"online.seasonTicketPerKs"} inputType={"number"}
                                        isMandatory={true} min={0} step={'0.01'}/>
                        </div>
                        <div className={"flex w-full -mt-3 ml-1"}>
                            <CheckboxField name={"online.seasonTicketPerKsWithZero"}
                                           label={"Počítat i z nulových"}/>
                        </div>
                    </div>
                </div>
                <div className={"flex w-1/4"}>
                    <InputField label={"Produkty -  %"} name={"online.productPercent"} inputType={"number"}
                                isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <div className={"flex flex-col w-full"}>
                        <div className={"flex w-full"}>
                            <InputField label={"Produkty - Kč/produkt"} name={"online.productPerKs"}
                                        inputType={"number"} isMandatory={true} min={0} step={'0.01'}/>
                        </div>
                        <div className={"flex w-full -mt-3 ml-1"}>
                            <CheckboxField name={"online.productPerKsWithZero"}
                                           label={"Počítat i z nulových"}/>
                        </div>
                    </div>
                </div>
                <div className={"flex w-1/4"}>
                    <InputField label={"Dobití kreditu mob. apl. -  %"} name={"online.creditPercent"}
                                inputType={"number"}
                                isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <InputField label={"Členství %"} name={"online.membershipPercent"} inputType={"number"}
                                isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <div className={"flex flex-col w-full"}>
                        <div className={"flex w-full"}>
                            <InputField label={"Členství - Kč/členství"} name={"online.membershipPerKs"}
                                        inputType={"number"} isMandatory={true} min={0} step={'0.01'}/>
                        </div>
                    </div>
                </div>
                <div className={"flex w-1/4"}>
                    <InputField label={"Předplatné %"} name={"online.subscriptionPercent"} inputType={"number"}
                                isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <div className={"flex flex-col w-full"}>
                        <div className={"flex w-full"}>
                            <InputField label={"Předplatné - Kč/Předplatné"} name={"online.subscriptionPerKs"}
                                        inputType={"number"} isMandatory={true} min={0} step={'0.01'}/>
                        </div>
                    </div>
                </div>

                <div className={"flex w-1/4"}>
                    <InputField label={"Časové sloty %"} name={"online.timeslotPercent"} inputType={"number"}
                                isMandatory={true} min={0} step={'0.01'}/>
                </div>
                <div className={"flex w-1/4"}>
                    <div className={"flex flex-col w-full"}>
                        <div className={"flex w-full"}>
                            <InputField label={"Časové sloty - Kč/Slot"} name={"online.timeslotPerKs"}
                                        inputType={"number"} isMandatory={true} min={0} step={'0.01'}/>
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default CommisionOnline
