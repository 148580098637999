import React, {useState} from "react";
import {Form} from "react-final-form";
import Currency from "../../../../../components/My/Currency";
import {apiSaveBillingSubject} from "../../../Actions";
import {useParams} from "react-router-dom";
import CurrencyField from "../../../../../FinalForm/CurrencyField";
import Toast from "cogo-toast";
import PercentField from "../../../../../FinalForm/PercentField";
import SelectField from "../../../../../FinalForm/SelectField";
import {Button} from "../../../../../components/My/Button";
import InputField from "../../../../../FinalForm/InputField";
import {getRowBlock, getRowWithText} from "./DataComponents";
import {FaInfo, IoIosInformationCircleOutline} from "react-icons/all";


const FormBilling = ({data, getDetail}) => {

    let [loadingSave, setLoadingSave] = useState(false)

    const {billingId} = useParams()
    const {subjectId} = useParams()


    const save = (values) => {
        setLoadingSave(true)
        apiSaveBillingSubject(billingId, subjectId, values, (data) => {
            Toast.success("Dokončeno.")
            setLoadingSave(false)
            getDetail()
        }, error => {
            console.log(error)
            Toast.warn("Došlo k chybě.")
            setLoadingSave(false)
        })
    }


    const getBlock = (text, canal, billingData) => {
        let ticketPrice = parseFloat(billingData.byType?.tickets[canal]?.totalPrice)
        let seasonTicketPrice = parseFloat(billingData.byType?.seasonTicket[canal]?.totalPrice)
        let productPrice = parseFloat(billingData.byType?.product[canal]?.totalPrice)
        let timeslotPrice = parseFloat(billingData?.byType?.timeSlot?.[canal]?.totalPrice)


        // reservation on cashier
        let reservationOnline = 0
        let reservationCashier = 0
        let commReservationOnline = 0
        let commReservationCashier = 0

        let membership = 0
        let subscription = 0
        let commMembership = 0
        let commsubscription = 0


        if (canal === "online") {
            membership = billingData.byType?.membership ? parseFloat(billingData.byType?.membership["online"]?.totalPrice) : 0
            subscription = billingData.byType?.subscription ? parseFloat(billingData.byType?.subscription["online"]?.totalPrice) : 0

            commMembership = billingData.byType?.membership ? parseFloat(billingData.byType?.membership["online"]?.enigooCommTotal) : 0
            commsubscription = billingData.byType?.subscription ? parseFloat(billingData.byType?.subscription["online"]?.enigooCommTotal) : 0


        }

        if (canal === "cashier") {
            reservationOnline = billingData.byType?.tickets["reservationOnline"]?.totalPrice ? parseFloat(billingData.byType?.tickets["reservationOnline"]?.totalPrice) : 0
            reservationCashier = billingData.byType?.tickets["reservationCashier"]?.totalPrice ? parseFloat(billingData.byType?.tickets["reservationCashier"]?.totalPrice) : 0

            commReservationOnline = billingData.byType?.tickets["reservationOnline"]?.totalPrice ? parseFloat(billingData.byType?.tickets["reservationOnline"]?.enigooCommTotal) : 0
            commReservationCashier = billingData.byType?.tickets["reservationCashier"]?.totalPrice ? parseFloat(billingData.byType?.tickets["reservationCashier"]?.enigooCommTotal) : 0
        }

        let commTicket = billingData.byType?.tickets[canal]?.enigooCommTotal
        let commSeasonTicket = billingData.byType?.seasonTicket[canal]?.enigooCommTotal
        let commProduct = billingData.byType?.product[canal]?.enigooCommTotal
        let commTimeslot = billingData.byType?.timeSlot?.[canal]?.enigooCommTotal


        return (
            <div className={"mt-2 flex flex-col w-full"}>
                <div className={"font-bold text-base mb-1"}>{text}</div>
                <div className={"flex flex-col"}>
                    {getRowBlock("z toho vstupenky", canal, "tickets", billingData, data)}
                    {getRowBlock("z toho permanentky", canal, "seasonTicket", billingData, data)}
                    {getRowBlock("z toho produkty", canal, "product", billingData, data)}
                    {getRowBlock("Časové sloty", canal, "timeSlot", billingData)}

                    {canal === "online" &&
                        <>
                            {getRowBlock("Členství", "online", "membership", billingData)}
                            {getRowBlock("Předplatné", "online", "subscription", billingData)}
                        </>

                    }

                    {canal === "cashier" &&
                        <>
                            {getRowBlock("Vyzvednuté rezervace z online", "reservationOnline", "tickets", billingData)}
                            {getRowBlock("Vyzvednuté rezervace z pokladny", "reservationCashier", "tickets", billingData)}
                        </>
                    }
                </div>
                <div className={"flex flex-row font-bold pb-1 pt-1 border-t-2 border-black"}>
                    <div className={"w-48 justify-center pl-1"}>Celkem</div>
                    <div className={"w-32 p-1"}>
                        <Currency currency={data?.subject?.currency} type={"text"}
                                  price={ticketPrice + seasonTicketPrice + productPrice + reservationOnline + reservationCashier + membership + subscription + timeslotPrice}/>
                    </div>
                    <div className={"w-32 p-1"}></div>
                    <div className={"w-32 p-1"}></div>
                    <div className={"w-32 p-1"}>
                        <Currency currency={data?.subject?.currency} type={"text"}
                                  price={commTicket + commSeasonTicket + commProduct + commReservationOnline + commReservationCashier + commMembership + commsubscription + commTimeslot}/>
                    </div>
                </div>
            </div>
        )
    }

    const getValue = (values) =>{
        if(values){
            return values
        }
        return 0
    }

    const getDiff = () => {
        let csob = 0;
        if(data.subject.mainOnlineBank === "CSOB_CZK") {
             csob = parseFloat(data?.billingData?.total.bankData?.CSOB_CZK?.onlineSales)
        }else{
            csob = parseFloat(data?.billingData?.total.bankData?.CSOB_EUR?.onlineSales)
        }

        let mobileCredit = data?.billingData?.byType?.mobileCredit.online?.totalPrice
        let onlineCredit = data?.billingData?.total?.online.credit?.sales

        let difference = csob - data.billingData?.total.online.totalPrice - mobileCredit + onlineCredit;

        if (difference === 0) return {text: "V pořádku", difference}
        if (difference > 0) return {
            text: "Na bance je více peněz než byl prodej. Např. provedeno storno ale nerefundováno.",
            difference
        }
        if (difference < 0) return {text: "Na bance je méně peněz.", difference}
    }

    const getDataOnline = () => {
        let commision = parseFloat(data.billingData.byType?.tickets["online"]?.enigooCommTotal) + parseFloat(data.billingData.byType?.tickets["invoice"]?.enigooCommTotal)
        commision += parseFloat(data.billingData.byType?.seasonTicket["online"]?.enigooCommTotal) + parseFloat(data.billingData.byType?.seasonTicket["invoice"]?.enigooCommTotal)
        commision += parseFloat(data.billingData.byType?.product?.["online"]?.enigooCommTotal) + parseFloat(data.billingData.byType?.product["invoice"]?.enigooCommTotal)
        commision += parseFloat(getValue(data.billingData.byType?.membership?.["online"]?.enigooCommTotal))
        commision += parseFloat(getValue(data.billingData.byType?.subscription?.["online"]?.enigooCommTotal))
        commision += parseFloat(getValue(data.billingData.byType?.timeSlot?.["online"]?.enigooCommTotal))

        // price
        let price = parseFloat(data.billingData.byType?.tickets["online"]?.totalPrice) + parseFloat(data.billingData.byType?.tickets["invoice"]?.totalPrice)
        price += parseFloat(data.billingData.byType?.seasonTicket["online"]?.totalPrice) + parseFloat(data.billingData.byType?.seasonTicket["invoice"]?.totalPrice)
        price += parseFloat(data.billingData.byType?.product["online"]?.totalPrice) + parseFloat(data.billingData.byType?.product["invoice"]?.totalPrice)
        price += parseFloat(getValue(data.billingData.byType?.membership?.["online"]?.totalPrice))
        price += parseFloat(getValue(data.billingData.byType?.subscription?.["online"]?.totalPrice))
        price += parseFloat(getValue(data.billingData.byType?.timeSlot?.["online"]?.totalPrice))



        return {commision, price}
    }

    const getBankByCurrency = () => {
        if(data.subject.mainOnlineBank === "CSOB_CZK") {
            return data?.billingData?.total.bankData?.CSOB_CZK?.onlineSales;
        }else{
            return data?.billingData?.total.bankData?.CSOB_EUR?.onlineSales;
        }
    }

    const getBank = () => {
        return (
            <div>
                <h5 className={"font-bold"}>Kontrola banky CSOB</h5>
                <div className={"flex flex-row pb-1 pt-1 bg-grey-200"}>
                    <div className={"w-48 justify-center pl-1 font-bold"}>{data?.billingData?.total.bankData?.CSOB_CZK?.name}</div>
                    <div className={"w-64 p-1"}>
                        <div>Celkem přijaté peníze</div>
                        <div className={"text-base"}>
                            <Currency currency={data?.subject?.currency} type={"text"}
                                      price={getBankByCurrency()}/>
                        </div>
                    </div>
                    <div className={"w-64 p-1 flex flex-col"}>
                        <div className={"flex flex-row"}><div className={"flex"}>Vrácené peníze z před. obd.</div> <IoIosInformationCircleOutline title={"Prodej prosinec, vráceno leden - částka braná z výpisu"} /></div>
                        <Currency currency={data?.subject?.currency} type={"text"}
                                  price={data?.billingData?.total.bankData?.CSOB_CZK?.onlineReturn}/>
                    </div>
                    <div className={"w-64 p-1 flex flex-col"}>
                        <div>Vrácené peníze ve stejném obd.</div>
                        <Currency currency={data?.subject?.currency} type={"text"}
                                  price={data?.billingData?.total.bankData?.CSOB_CZK?.onlineReturn}/>
                    </div>
                </div>
                <div className={"flex flex-row pb-1 pt-1 bg-grey-200"}>
                    <div className={"w-48 justify-center pl-1 font-bold"}>Platební brána prodej</div>
                    <div className={"w-64 p-1"}>
                        <div>Celkem systém online</div>
                        <div className={"text-base"}>
                            <Currency currency={data?.subject?.currency} type={"text"}
                                      price={data?.billingData?.total.online.sales.sales}/>
                        </div>
                    </div>
                    <div className={"w-64 p-1 flex flex-col"}>
                        <div>Vráceno systémem z před. obd.</div>
                        <Currency currency={data?.subject?.currency} type={"text"}
                                  price={data?.billingData?.total.online.sales.return}/>
                    </div>
                </div>
                <div className={"flex flex-row pb-1 pt-1 bg-grey-200"}>
                    <div className={"w-48 justify-center pl-1 font-bold"}>Manipulační poplatky</div>
                    <div className={"w-64 p-1"}>
                        <div>Celkem přijaté peníze</div>
                        <div className={"text-base"}>
                            <Currency currency={data?.subject?.currency} type={"text"}
                                      price={data?.billingData?.total?.online.manipulation.sales}/>
                        </div>
                    </div>
                    <div className={"w-64 p-1 flex flex-col"}>
                        <div>Vrácené peníze na bance</div>
                        <Currency currency={data?.subject?.currency} type={"text"}
                                  price={data?.billingData?.total.online.manipulation.return}/>
                    </div>
                </div>
                <div className={"flex flex-row pb-1 pt-1 bg-grey-200"}>
                    <div className={"w-48 justify-center pl-1 font-bold"}>Poštovné</div>
                    <div className={"w-64 p-1"}>
                        <div>Celkem přijaté peníze</div>
                        <div className={"text-base"}>
                            <Currency type={"text"}
                                      price={data?.billingData?.total.online.postage?.sales ? data?.billingData?.total.online.postage?.sales : 0}/>
                        </div>
                    </div>
                    <div className={"w-64 p-1 flex flex-col"}>
                        <div>Vrácené peníze na bance</div>
                        {/*<Currency currency={data?.subject?.currency} type={"text"}
                                  price={data?.billingData?.total.online.postage?.onlineStorno?.price ? data?.billingData?.totalPostage?.onlineStorno?.price : 0}/>
                   */} </div>
                </div>
                <div className={"flex flex-row pb-1 pt-1 bg-grey-200"}>
                    <div className={"w-48 justify-center pl-1 font-bold"}>Dobití mob. aplikace</div>
                    <div className={"w-64 p-1"}>
                        <div>Celkem přijaté peníze</div>
                        <div className={"text-base"}>
                            <Currency type={"text"}
                                      price={data?.billingData?.byType?.mobileCredit.online?.totalPrice ? data?.billingData?.byType?.mobileCredit.online?.totalPrice: 0}/>
                        </div>
                    </div>
                    <div className={"w-64 p-1 flex flex-col"}>
                        <div>Vrácené peníze na bance</div>
                        {/*<Currency currency={data?.subject?.currency} type={"text"}
                                  price={data?.billingData?.total.online.postage?.onlineStorno?.price ? data?.billingData?.totalPostage?.onlineStorno?.price : 0}/>
                   */} </div>
                </div>
                <div className={"flex flex-row pb-1 pt-1 bg-grey-200"}>
                    <div className={"w-48 justify-center pl-1 font-bold"}>Uplatněné vouchery v kombinaci s platbou online</div>
                    <div className={"w-64 p-1"}>
                        <div>V kombinaci s online platbou</div>
                        <div className={"text-base"}>
                            <Currency currency={data?.subject?.currency} type={"text"}
                                      price={data?.billingData?.total.online.voucher.sales}/>
                        </div>
                    </div>
                    <div className={"w-64 p-1 flex flex-col"}>
                        <div>Vrácené vouchery</div>
                        <Currency currency={data?.subject?.currency} type={"text"} price={data?.billingData?.total.online.voucher.return}/>
                    </div>
                </div>
                <div className={"flex flex-row pb-1 pt-1 bg-grey-200"}>
                    <div className={"w-48 justify-center pl-1 font-bold"}>Uplatněný kredit v kombinaci s platbou
                        online
                    </div>
                    <div className={"w-64 p-1"}>
                        <div>V kombinaci s online platbou</div>
                        <div className={"text-base"}>
                            <Currency currency={data?.subject?.currency} type={"text"}
                                      price={data?.billingData?.total.online.credit.sales}/>
                        </div>
                    </div>
                    <div className={"w-64 p-1 flex flex-col"}>
                        <div>Vrácené peníze na bance</div>
                        {/*<Currency currency={data?.subject?.currency} type={"text"} price={data?.billingData?.totalPostage?.onlineStorno?.price ? data?.billingData?.totalPostage?.onlineStorno?.price : 0}/>*/}
                    </div>
                </div>
                <div className={"flex flex-row pb-1 pt-1 bg-grey-200"}>
                    <div className={"w-48 justify-center pl-1 font-bold"}>Rozdíl</div>
                    <div className={"w-64 p-1"}>
                        <div>Celkem</div>
                        <div className={"text-base"}>
                            <Currency currency={data?.subject?.currency} type={"text"} price={getDiff().difference}/>
                        </div>
                    </div>
                    <div className={"w-128 p-1"}>
                        <div>Celkem</div>
                        <div className={"text-base"}>
                            {getDiff().text}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    const getBankFee = (values, form) => {
        return (
            <div>
                <h5 className={"font-bold"}>Bankovní poplatky</h5>
                <div className={"flex flex-row pb-1 pt-1 bg-grey-200"}>
                    <div className={"w-48 justify-center pl-1 font-bold"}>ČSOB</div>
                    <div className={"w-64 p-1"}>
                        <div>Prodej online</div>
                        <div className={"text-base"}>
                            <Currency currency={data?.subject?.currency} type={"text"}
                                      price={data?.billingData?.total?.online.totalPrice}/>
                        </div>
                    </div>
                    <div className={"w-48 p-1 flex flex-col"}>
                        <div>% Provize</div>
                        <PercentField name={"billingData.total.online.commPercent"}
                                      onChangeField={(value) => {
                                          form.change(`billingData.total.online.enigooComm`, parseFloat((data?.billingData?.total.online.totalPrice / 100) * value))
                                      }}/>
                    </div>
                    <div className={"w-64 p-1 flex flex-col"}>
                        <div className={"font-bold"}>Bankovní poplatky ČSOB na faktuře</div>
                        <CurrencyField currency={data?.subject?.currency} name={"billingData.total.online.enigooComm"}
                                       selfClass={"w-full h-8 pl-1"}/>
                    </div>
                </div>
                <div className={"flex flex-row pb-1 pt-1 bg-grey-200"}>
                    <div className={"w-48 justify-center pl-1 font-bold"}>FIO fakturace</div>
                    <div className={"w-64 p-1"}>
                        <div>Celkem přijaté peníze</div>
                        <div className={"text-base"}>
                            <Currency currency={data?.subject?.currency} type={"text"}
                                      price={data?.billingData?.total?.invoice.totalPrice}/>
                        </div>
                    </div>
                    <div className={"w-48 p-1 flex flex-col"}>
                        <div>% Provize</div>
                        <PercentField name={"billingData.total.invoice.commPercent"}
                                      onChangeField={(value) => {
                                          form.change(`billingData.total.invoice.enigooComm`, parseFloat((data?.billingData?.total.invoice.totalPrice / 100) * value))
                                      }}/>
                    </div>
                    <div className={"w-64 p-1 flex flex-col"}>
                        <div className={"font-bold"}>Bankovní poplatky FIO na faktuře</div>
                        <CurrencyField currency={data?.subject?.currency}  name={"billingData.total.invoice.enigooComm"}
                                       selfClass={"w-full h-8 pl-1"}/>
                    </div>
                </div>
                <div className={"flex flex-row pb-1 pt-1 bg-grey-300"}>
                    <div className={"w-48 justify-center pl-1 font-bold text-base"}>Celkem</div>
                    <div className={"w-64 p-1"}>
                        <div className={"font-bold"}>Celkem přijaté peníze</div>
                        <div className={"text-base font-bold"}>
                            <Currency currency={data?.subject?.currency} type={"text"}
                                      price={data?.billingData?.total?.online.totalPrice + data?.billingData?.total?.invoice.totalPrice}/>
                        </div>
                    </div>
                    <div className={"w-48 p-1 flex flex-col"}>
                        <div></div>
                    </div>
                    <div className={"w-64 p-1 flex flex-col"}>
                        <div className={"font-bold"}>Bankovní poplatky celkem</div>
                        <Currency currency={data?.subject?.currency}
                                  price={parseFloat(values.billingData.total.online.enigooComm) + parseFloat(values.billingData.total.invoice.enigooComm)}
                                  type={"text"}
                                  selfClass={"w-full text-base font-bold"}/>
                    </div>
                </div>
            </div>
        )
    }


    const getCommision = () => {

        if (data?.billingData?.total.totalRealityCommision && data?.billingData?.total.totalRealityCommision > 0) {
            return (
                <>
                    {getRowWithText("Provize enigoo", data?.billingData?.total.totalRealityCommision)}
                    <div className={"flex flex-row pb-1 pl-1 text-sm"}>Provize změněna paušálem, minimálním plněním nebo
                        maximálním plněním.
                    </div>
                </>
            )
        }

        return getRowWithText("Provize enigoo", data?.billingData?.total.totalCommisionEnigoo, data)
    }

    const getReturns = () => {
      if(data.subject.mainOnlineBank === "CSOB_CZK"){
          return data?.billingData?.total?.bankData?.CSOB_CZK.onlineReturn + data?.billingData?.total?.invoice.sales.return
      }else {
          return data?.billingData?.total?.bankData?.CSOB_EUR.onlineReturn + data?.billingData?.total?.invoice.sales.return
      }
    }



    const getSummary = (values) => {

        return (
            <div className={"flex flex-row mt-4 text-base"}>
                <div className={"flex flex-col w-1/2"}>
                    <h5 className={"font-bold"}>Řádky na faktuře</h5>

                    {getCommision()}

                    {getRowWithText("Manipulační poplatky", data?.billingData?.total?.online.manipulation.sales + data?.billingData?.total?.invoice.manipulation.sales, data)}
                    {!data?.billing?.rules?.postageMakeSubject && getRowWithText("Poštovné", data?.billingData?.total?.totalPostage, data)}
                    {getRowWithText("Vratky z předchozích období", getReturns(), data)}
                    {getRowWithText("Bankovní poplatky", parseFloat(values.billingData.total.online.enigooComm) + parseFloat(values.billingData.total.invoice.enigooComm), data)}


                    {getRowWithText("Zálohový list", data?.billingData?.total.totalZLV, data)}

                </div>
                <div className={"flex flex-col w-1/2"}>
                    <h5 className={"font-bold"}>Nebude na faktuře</h5>

                    {data?.billing?.rules?.postageMakeSubject && getRowWithText("Poštovné", data?.billingData?.total?.totalPostage, data)}

                </div>
            </div>
        )
    }


    const getForm = () => {
        return (
            <Form onSubmit={save}
                      initialValues={{billingData: data.billingData, status: data.status, note: data.note}}
                      render={({handleSubmit, values, form}) => {
                          return (
                              <>
                                  {getBankFee(values, form)}
                                  {getSummary(values)}

                                  <div className={"flex flex-row pb-1 pt-1 mt-4 bg-grey-200"}>
                                              <div className={"w-1/2 p-1"}>
                                                  <SelectField name={"status"} isMandatory={true} options={[
                                                      {label: "Čeká na zpracování", value: 0},
                                                      {label: "Nelze dokončit", value: 1},
                                                      {label: "Úspěšně dokončeno", value: 2},
                                                      {label: "Dokončeno - import do abr. manuálně", value: 3},
                                                      {label: "Neaktivní měsíc", value: 4},
                                                      {label: "Vyúčtování po akci manuálně", value: 5}
                                                  ]} label={"Stav vyúčtování"} disabled={!data.billing.open || data.billing.future}/>
                                              </div>
                                              <div className={"w-1/2 p-1"}>
                                                  <InputField label={"Poznámka"} name={"note"} isMandatory={false}/>
                                              </div>
                                  </div>
                                  <div className={"flex flex-row pb-1 pt-1"}>
                                      <Button text={"Uložit aktuální stav"} loading={loadingSave} onClick={handleSubmit}/>
                                  </div>
                                </>
                          )
                      }}/>
        )
    }

    return (
        <>
            <div className={"flex flex-row"}>
                <div className={"flex flex-col w-3/4"}>
                    {getBlock("Objem online", "online", data.billingData)}
                    {getBlock("Objem fakturace", "invoice", data.billingData)}
                </div>
                <div className={"flex flex-col w-1/4"}>
                    <h5 className={"text-center font-bold"}>Objem celkem</h5>
                    <div className={"flex flex-col text-center w-full align-middle"}>
                        <div className={"w-full text-xl font-bold mt-40"}>Prodej</div>
                        <div className={"w-full text-xl"}><Currency currency={data?.subject.currency} type={"text"} price={(getDataOnline())?.price}/></div>
                        <div className={"w-full text-xl font-bold"}>Provize</div>
                        <div className={"w-full text-xl"}><Currency currency={data?.subject.currency} type={"text"} price={(getDataOnline())?.commision}/></div>
                    </div>
                </div>
            </div>

            {getBlock("Objem pokladna", "cashier", data.billingData)}

            {getBank()}

            {getForm()}
        </>
    )
}


export default FormBilling
